var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "single" }, [
    !_vm.loading
      ? _c("section", { staticClass: "form" }, [
          _c("h2", [_vm._v(_vm._s(_vm.i18n.t("Change Password")))]),
          _vm._v(" "),
          _c(
            "form",
            {
              attrs: { autocomplete: "off" },
              on: { submit: _vm.handleSubmit },
            },
            [
              _c("InputGroup", { ref: "form", attrs: { config: _vm.config } }),
              _vm._v(" "),
              _c("FooterField", {
                attrs: {
                  submit: { label: _vm.i18n.t("Change") },
                  cancel: true,
                },
                on: { cancel: _vm.handleCancel, submit: _vm.handleSubmit },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }