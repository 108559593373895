<template>
	<main class="single">
		<section
			v-if="!loading"
			class="form"
		>
			<h2>{{ i18n.t('Change Password') }}</h2>
			<form
				autocomplete="off"
				@submit="handleSubmit"
			>
				<InputGroup
					ref="form"
					:config="config"
				/>
				<FooterField
					:submit="{label: i18n.t('Change')}"
					:cancel="true"
					@cancel="handleCancel"
					@submit="handleSubmit"
				/>
			</form>
		</section>
	</main>
</template>

<script>
import InputGroup from 'tucows-ui-components/src/Input/InputGroup.vue';
import FooterField from 'tucows-ui-components/src/FooterField.vue';
import {mapActions, mapState} from 'vuex';
import i18n from '@/lib/i18n';
import _get from 'lodash/get';

/**
 * @class PasswordChange
 *
 * Change your existing password while logged in
 */
export default {
	components: {
		FooterField,
		InputGroup
	},
	/**
	 * @ignore
	 */
	data() {
		return {
			config: [
				{
					autocomplete: 'off',
					autocapitalize: 'off',
					autocorrect: 'off',
					name: 'password',
					required: true,
					type: 'password',
					label: i18n.t('Current Password'),
					value: ''
				},
				{
					autocapitalize: 'off',
					autocorrect: 'off',
					name: 'new_password',
					compType: 'password',
					required: true,
					label: i18n.t('New Password'),
					value: '',
					validations: [
						{
							id: 1,
							regex: /[0-9]/,
							message: this.i18n.t('Must have at least one number'),
							status: false
						},
						{
							id: 2,
							regex: /^(?=.*[A-Z])(?=.*[a-z]).*$/,
							message: this.i18n.t('Must have lower and upper case letter(s)'),
							status: false
						},
						{
							id: 3,
							regex: /^(?=.*[@$-/:-?{-~!"^_'[\]]).*$/,
							message: this.i18n.t('Must have a special character'),
							status: false
						},
						{
							id: 4,
							regex: /^.{14,}$/,
							message: this.i18n.t('Must be 14 or more characters'),
							status: false
						}
					]
				}
			]
		};
	},
	computed: {
		...mapState({
			errors: (state) => _get(state, 'user.errors'),
			loading: (state) => _get(state, 'common.loading')
		})
	},
	methods: {
		...mapActions({
			showSuccess: 'common/showSuccess',
			update: 'user/update'
		}),
		/**
		 * Redirect user back to home page
		 */
		handleCancel() {
			this.$router.push('/');
		},
		/**
		 * Change password
		 */
		handleSubmit() {
			// If form is valid then submit and redirect to search
			if (this.$refs.form.isValid()) {
				const data = this.$refs.form.getData();
				this.update({
					password: _get(data, 'password'),
					new_password: _get(data, 'new_password')
				}).then(() => {
					if (!this.errors) {
						this.showSuccess();
						this.$router.push('/search');
					}
				});
			}
		}
	}
};
</script>
